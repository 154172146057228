define("travis/routes/dashboard/builds", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    auth: Ember.inject.service(),
    model: function model(params) {
      var currentUserId = this.get('auth.currentUser.id');
      var eventTypes = ['api', 'pull_request', 'push', 'release'];
      var query = {
        limit: 30,
        event_type: eventTypes.join(','),
        include: 'build.jobs'
      };
      return this.store.filter('build', query, function (build) {
        return build.get('createdBy.id') == currentUserId && eventTypes.includes(build.get('eventType'));
      });
    }
  });

  _exports.default = _default;
});